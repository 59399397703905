
export enum VERIFY_SLIP_STATUS {
	APPROVED = 'APPROVED',
	REJECT = 'REJECT',
	INVALID = 'INVALID',
}
export type VerifySlipStatusType = {
	[key in VERIFY_SLIP_STATUS]: {
		text: string
		color: string
	}
}
export const VERIFY_SLIP_STATUS_TYPE: VerifySlipStatusType = {
	INVALID: {
		text: 'ไม่สามรถตรวจสอบได้',
		color: 'warning',
	},
	APPROVED: {
		text: 'ถูกต้อง',
		color: 'success',
	},
	REJECT: {
		text: 'ไม่ถูกต้อง',
		color: 'danger',
	},
}